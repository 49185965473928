import './LogInForm.css';
import { useState } from "react"
import { login, setAuthToken} from "../../api"
import { useNavigate } from 'react-router-dom';
import WalletAuthButton  from '../WalletAuthButton/WalletAuthButton'

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { token } = await login(email, password)
      localStorage.setItem('token', token);
      setAuthToken(token);
      navigate('/home');
    } catch(error) {
      console.error('Login error', error.response.data);
    }
  }

  return (
    <div className="login-form">
      <h1>Welcome back! 👋</h1>
      <p>Enter your credentials to access your account</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email address</label>
          <input 
            type="email" 
            id="email" 
            placeholder="example@email.com" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            style={{ fontSize: '24px' }}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input 
            type="password" 
            id="password" 
            placeholder="Min 8 chars" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ fontSize: '24px' }}
            required
            />
        </div>
        <div className="form-options">
          <label>
            <input type="checkbox" /> Keep me logged in
          </label>
          <a href="#forgot-password">Forgot password?</a>
        </div>
        <button type="submit" className="sign-in-button">Sign In</button>
        {/* <button type="button" className="wallet-button">Disonnect Wallet</button> */}
      </form>
      <WalletAuthButton mode="login" />
      <p className="sign-up-link">Don't have an account? <a href="/signup">Sign Up</a></p>
    </div>
  );
}

export default LoginForm;