// import CheckoutButton from "./TestStripe";

// export default function YourPage() {
//   return (
//     <div>
//       <h1>Get Premium Access</h1>
//       <CheckoutButton planType="monthly" text="Monthly"/>
//       <CheckoutButton planType="yearly" text="Yearly"/>
//     </div>
//   );
// }

// import CheckoutButton from "./TestStripe";
// import { Card, CardBody, CardHeader, CardFooter } from "@nextui-org/react";
// import logo from "../../../assets/peacepal_logo_2.png";

// export default function YourPage() {
//   return (
//     <div className="min-h-screen flex bg-[#191b29] items-center justify-center p-4">
//       <Card className="min-w-[70%] max-w-lg  bg-[#191b28] text-white">
//         <CardHeader className="flex flex-col items-center gap-4 pb-2">
//           <div className="flex justify-center">
//             <img
//               src={logo}
//               alt="PeacePal Logo"
//               className="h-32 w-auto"
//             />
//           </div>
//           <div className="text-center space-y-2">
//             <h1 className="text-3xl font-bold bg-gradient-to-r from-[#54a3c3] to-[#a16bdd] bg-clip-text text-transparent">
//               You're Almost There!
//             </h1>
//             <p className="text-gray-400">
//               Select a plan to unlock premium access to all our AI companions
//             </p>
//           </div>
//         </CardHeader>

//         <CardBody className="space-y-6 pt-4">
//           <div className="flex flex-col gap-4 px-4">
//             <div className="space-y-2">
//               <h3 className="text-lg font-semibold text-center">Monthly</h3>
//               <div className="text-center">
//                 <span className="text-2xl font-bold">$9.99</span>
//                 <span className="text-gray-400">/month</span>
//               </div>
//               <div className="flex justify-center">
//                 <CheckoutButton planType="monthly" text="Monthly"/>
//               </div>
//             </div>

//             <div className="space-y-2">
//               <h3 className="text-lg font-semibold text-center">Yearly</h3>
//               <div className="text-center">
//                 <span className="text-2xl font-bold">$99.99</span>
//                 <span className="text-gray-400">/year</span>
//                 <div>
//                   <span className="text-sm text-green-400">Save 17%</span>
//                 </div>
//               </div>
//               <div className="flex justify-center">
//                 <CheckoutButton planType="yearly" text="Yearly"/>
//               </div>
//             </div>
//           </div>
//         </CardBody>

//         <CardFooter className="justify-center">
//           <p className="text-sm text-gray-400">Secure payment powered by Stripe</p>
//         </CardFooter>
//       </Card>
//     </div>
//   );
// }

import CheckoutButton from "./TestStripe";
import { Card, CardBody, CardHeader, CardFooter } from "@nextui-org/react";
import logo from "../../public/peacepal_logo_2.png";

export default function YourPage() {
  return (
    <div className="min-h-screen bg-[#191b29] flex items-center justify-center p-4">
      <Card className="w-full max-w-4xl bg-[#191b29] text-white">
        <CardHeader className="flex flex-col items-center gap-4 pb-2">
          <div className="flex justify-center">
            <img
              src={logo}
              alt="PeacePal Logo"
              className="h-32 md:h-64 w-auto"
            />
          </div>
          <div className="text-center space-y-2">
            <h1 className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-[#54a3c3] to-[#a16bdd] bg-clip-text text-transparent">
              You're Almost There!
            </h1>
            <p className="text-gray-400 text-base md:text-lg">
              Select a plan to unlock premium access to all our AI companions
            </p>
          </div>
        </CardHeader>

        <CardBody className="space-y-6 pt-4">
          <div className="flex flex-col md:flex-row gap-6 md:gap-24 px-4 justify-center">
            <div className="text-center">
              <h3 className="text-lg md:text-xl font-semibold mb-4">Monthly</h3>
              <div className="mb-2">
                <span className="text-2xl md:text-3xl font-bold">$20.00</span>
                <span className="text-gray-400">/month</span>
              </div>
              <div className="invisible mb-4">
                <span className="text-sm md:text-base text-green-400">Save 17%</span>
              </div>
              <CheckoutButton planType="monthly" text="Select"/>
            </div>

            <div className="text-center">
              <h3 className="text-lg md:text-xl font-semibold mb-4">Yearly</h3>
              <div className="mb-2">
                <span className="text-2xl md:text-3xl font-bold">$90.00</span>
                <span className="text-gray-400">/year</span>
              </div>
              <div className="mb-4">
                <span className="text-sm md:text-base text-green-400">Save 63%</span>
              </div>
              <CheckoutButton planType="yearly" text="Select"/>
            </div>
          </div>
        </CardBody>

        <CardFooter className="justify-center">
          <p className="text-sm md:text-base text-gray-400">Secure payment powered by Stripe</p>
        </CardFooter>
      </Card>
    </div>
  );
}