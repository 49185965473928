import LeftPanel from './LeftPanel';
import LoginForm from './LoginForm';

const LoginPage = () => {
  return (
    <div className="flex h-screen">
      <div className="md:flex hidden flex-1">
        <LeftPanel />
      </div>
      <LoginForm />
    </div>
  );
}

export default LoginPage;