import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import {NextUIProvider} from "@nextui-org/react"
// import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import '@rainbow-me/rainbowkit/styles.css';

const config = getDefaultConfig({
    appName: 'PeacePal AI',
    projectId: '9ddd41c78f0cc77fc7db298cfc273a38',
    chains: [mainnet, polygon, optimism, arbitrum, base],
    ssr: false, // If your dApp uses server side rendering (SSR)
  });

const queryClient = new QueryClient();

// import { Buffer } from 'buffer';
// window.Buffer = Buffer;

ReactDOM.createRoot(document.getElementById('root')).render(
    <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
            <RainbowKitProvider>
                <NextUIProvider>
                    <App />
                </NextUIProvider>
            </RainbowKitProvider>
        </QueryClientProvider>
    </WagmiProvider>
)
