import LeftPanel from './LeftPanel';
import RegistrationForm from './RegistrationForm';

const RegistrationPage = () => {
  return (
    <div className="flex h-screen bg-[#1a1a2e]">
      <div className="md:flex hidden flex-1">
        <LeftPanel />
      </div>
      <RegistrationForm />
    </div>
  );
}

export default RegistrationPage;