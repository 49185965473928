import BaseCheckoutButton from './BaseCheckoutButton'
import { Card, CardBody, CardHeader, CardFooter } from "@nextui-org/react";
import logo from "../../public/peacepal_logo_2.png";

export default function BasePage() {
  return (
    <div className="min-h-screen bg-[#191b29] flex items-center justify-center p-4">
      <Card className="w-full max-w-4xl bg-[#191b29] text-white">
        <CardHeader className="flex flex-col items-center gap-4 pb-2">
          <div className="flex justify-center">
            <img
              src={logo}
              alt="PeacePal Logo"
              className="h-32 md:h-64 w-auto"
            />
          </div>
          <div className="text-center space-y-2">
            <h1 className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-[#54a3c3] to-[#a16bdd] bg-clip-text text-transparent">
              You're Almost There!
            </h1>
            <p className="text-gray-400 text-base md:text-lg">
              Select a plan to unlock premium access to all our AI companions
            </p>
          </div>
        </CardHeader>

        <CardBody className="space-y-6 pt-4">
          <div className="flex flex-col md:flex-row gap-6 md:gap-24 px-4 justify-center">
            <div className="text-center">
              <h3 className="text-lg md:text-xl font-semibold mb-4">Monthly</h3>
              <div className="mb-2">
                <span className="text-2xl md:text-3xl font-bold">$20.00</span>
                <span className="text-gray-400">/month</span>
              </div>
              <div className="invisible mb-4">
                <span className="text-sm md:text-base text-green-400">Save 17%</span>
              </div>
              <BaseCheckoutButton planType="monthly" text="Select"/>
            </div>

            <div className="text-center">
              <h3 className="text-lg md:text-xl font-semibold mb-4">Yearly</h3>
              <div className="mb-2">
                <span className="text-2xl md:text-3xl font-bold">$90.00</span>
                <span className="text-gray-400">/year</span>
              </div>
              <div className="mb-4">
                <span className="text-sm md:text-base text-green-400">Save 63%</span>
              </div>
              <BaseCheckoutButton planType="yearly" text="Select"/>
            </div>
          </div>
        </CardBody>

        <CardFooter className="justify-center">
          <p className="text-sm md:text-base text-gray-400">Secure payment powered by Coinbase Commerce</p>
        </CardFooter>
      </Card>
    </div>
  );
}