import LogInPage from './components/LogInPage/LogInPage';
import RegistrationPage from './components/RegistrationPage/RegistrationPage';
import ProtectedRoute from './components/ProtectedRoute';
// import HomePage from './components/HomePage/HomePage'
import Chat from './components/Chat/Chat'
// import Web3_test from './components/web3_auth.tsx';
import { useDisconnect, useAccount } from 'wagmi';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes, Route, Link, Navigate
} from 'react-router-dom'
import './App.css'
import {Button} from "@nextui-org/react";
import {Chip} from "@nextui-org/chip";
import {Image} from "@nextui-org/image";
import PalCard from './components/HomePage/PalCard/PalCard';
import NavBar from './components/HomePage/NavBar';
import peter from '../public/Peter.png'
import genie from '../public/Genie.png'
import satoshi from '../public/Satoshi.png'
import shonna from '../public/Shonna.png'
import YourPage from './components/StripePage';
import Success from './components/Success';
import Cancel from './components/Cancel';
import BasePage from './components/BasePage'

const Sidebar = () => {
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();
  const { isConnected } = useAccount();

  const handleSignOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('wallet_address');
    if (isConnected) {
        disconnect();
    }
    navigate('/login');
  };

  useEffect(() => {
    if (!isConnected && localStorage.getItem('wallet_address')) {
        localStorage.removeItem('token');
        localStorage.removeItem('wallet_address');
        navigate('/login');
    }
  }, [isConnected, navigate]);
  
  return (
  <div className="w-64 bg-[#191b29] p-4 flex flex-col h-screen fixed left-0 top-0">
    <div className="flex items-center mb-8">
      {/* <div className="w-12 h-12 bg-pink-500 rounded-full mr-2"></div>
      <div className="w-12 h-12 bg-blue-500 rounded-full"></div> */}
    </div>
    <h2 className="text-gray-400 mb-4">PAL CATEGORIES</h2>
    <div className="bg-gray-700 p-2 rounded">
      <span className="flex items-center">
        <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="currentColor">
          <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z" />
        </svg>
        All Pals
      </span>
    </div>
    <div className="mt-auto mb-10">
      <Button onClick={handleSignOut} className="flex items-center text-pink-500 bg-[#191b29]">
        <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
          <polyline points="16 17 21 12 16 7" />
          <line x1="21" y1="12" x2="9" y2="12" />
        </svg>
        Sign Out
      </Button>
    </div>
  </div>
)};

const FilterButtons = () => (
  <div className="flex space-x-2 mb-6">
    {['All', 'Shonna', 'Peter', 'Genie', 'Satoshi'].map((name, index) => (
      <button 
        key={name}
        className={`px-4 py-2 rounded-full ${index === 0 ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white' : 'bg-gray-700 text-white'}`}
      >
        {name}
      </button>
    ))}
  </div>
);

const Home = () => {
  let peter_pal = {
    name: "Peter",
    description: "Peter brings a smile to your face and a pep to your step. Get ready for uplifting encouragement and clever insights, Peter style!",
    image: peter
  }
  let genie_pal = {
    name: "Genie",
    description: "Hello! Need assistance with your homework? Whether it's math problems, essay writing, or research questions, I'm here to lend a hand. Share your homework tasks, and let's work through them together!",
    image: genie
  }
  let satoshi_pal = {
    name: "Satoshi",
    description: "Unlock the secrets of the crypto world with our intelligent Satoshi Nakamoto bot! Whether you're a seasoned investor or just starting your journey, our bot is your trusted companion in navigating the complexities of cryptocurrency",
    image: satoshi
  }
  let shonna_pal = {
    name: "Shonna",
    description: "Your Sassy Sidekick for Real Talk and Good Vibes! With a Dash of humor and a whole lot of attitude, Shonna keeps it real.",
    image: shonna
  }
  


  return (
    <>
    <NavBar/>
    <div className="pt-20">
      <div className="hidden md:block">
        <Sidebar/>
      </div>
      <div className="flex">
        <div className="flex-1 md:ml-64 pt-8 pb-8 pl-2 px-2 md:pl-16 bg-[#000210] min-h-screen">
        <div className="hidden md:block">
          <FilterButtons/>
        </div>
          <div className="flex gap-3 flex-wrap xs:justify-center md:justify-start">
            <PalCard {...genie_pal}/>
            <PalCard {...satoshi_pal}/>
            <PalCard {...shonna_pal}/>
            <PalCard {...peter_pal}/>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

const App = () => {
  const padding = {
    padding: 5
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<LogInPage/>}/>
          <Route path="/signup" element={<RegistrationPage/>}/>
          <Route path="/home" element={
            <ProtectedRoute>
              <Home/>
            </ProtectedRoute>
          }/>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/chat" element={
            <ProtectedRoute>
              <Chat/>
            </ProtectedRoute>
          }/>
          <Route path="/pal_card" element={<PalCard/>}/>
          <Route path="/pal_card_multiple" element={<Home/>}/>
          <Route path="/success" element={<Success/>}/>
          <Route path="/cancel" element={<Cancel/>}/>
          <Route path="/pay" element={<YourPage/>}/>
          <Route path="/pay_web3" element={<BasePage/>}/>
          {/* <Route path="/web3" element={<Web3_test/>}/> */}
        </Routes>

        {/* <div> 
          <p>So this a part of the template regardless? fr </p> 
        </div> */}
      </Router>
    </>
  )
}

export default App
