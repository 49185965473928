import {Card, CardHeader, CardBody, CardFooter, Image} from "@nextui-org/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import './test.css'


const PalCard = ({name, image, description}) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        console.log("There was a click")
        navigate(`/chat?character=${name}`);
    };
    return (
        <div onClick={handleClick}>
        <Card className="bg-[#191b29] text-white max-w-96 min-h-[300px]  px-4 pr-10 group" 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            radius="lg" shadow="lg"
            style={{ 
                border: isHovered ? "2px solid #32546b" : "2px solid transparent",
                transition: "border-color 0.3s ease",
            }}
            >
            <CardBody>
                <div className="flex flex-col items-start">
                    <div className="flex flex-row items-center"> {/* Set a fixed width */}
                        <Image 
                            width={64}
                            src={image}
                            className="mb-2"
                        />
                    
                        <h3 className="group-hover:text-white text-gray-150">{name}</h3>
                    </div> 
                    
                    <p className="group-hover:text-white text-gray-400 leading-6 mt-auto">{description}</p>
                </div>
                <div className="flex space-x-2 mt-auto">
                <span className="bg-gray-700 px-3 py-1 rounded-full">{name}</span>
                <span className="bg-gray-700 px-3 py-1 rounded-full text-blue-400">Monthly</span>
                </div>
            </CardBody>
        </Card>
        </div>
    )
}

export default PalCard;