// src/api.js
import axios from 'axios';

// const API_URL = 'http://localhost:3000/api';
const API_URL = 'https://peacepal-auth-cf9b37894df1.herokuapp.com/api'

export const signup = async (email, password) => {
  // TODO: Have use autheticated request for sign up too
  const response = await authenticatedRequest.post(`/auth/signup`, { email, password });
  return response.data;
};

export const login = async (email, password) => {
  const response = await authenticatedRequest.post(`/auth/login`, { email, password });
  return response.data;
};

// New wallet authentication functions
export const walletLogin = async (address) => {
  const response = await authenticatedRequest.post(`/auth/wallet-login`, { 
    walletAddress: address 
  });
  return response.data;
};

export const walletSignup = async (address) => {
  const response = await authenticatedRequest.post(`/auth/wallet-signup`, { 
    walletAddress: address 
  });
  return response.data;
};

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token;
  } else {
    delete axios.defaults.headers.common['x-auth-token'];
  }
};

export const verifyToken = async () => {
  const token = localStorage.getItem("token")
  if (!token) {
    return false
  }

  try {
    const response = await authenticatedRequest.get(`/auth/verify-token`)
    return response.data.valid
  } catch (error) {
    console.error("Token verification failed", error)
    return false
  }
}

// New function for authenticated requests
export const authenticatedRequest = axios.create({
  baseURL: API_URL,
});

authenticatedRequest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
      config.headers['x-vercel-protection-bypass'] = "g7ZTq4JlcHX5bIc0JJ4fi0GuqNd9C0gp"
    }
    return config;
  },
  (error) => Promise.reject(error)
);