import './RegistrationForm.css';
import { useState } from "react"
import { signup, setAuthToken} from "../../api"
import { useNavigate } from 'react-router-dom';
import  WalletAuthButton  from '../WalletAuthButton/WalletAuthButton'

// TODO: Make login also take in Name

const RegistrationForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { token } = await signup(email, password)
      localStorage.setItem('token', token);
      setAuthToken(token);
      navigate('/home');
    } catch(error) {
      console.error('Signup error', error.response.data);
    }
  }

  return (
    <div className="registration-form">
      <h2>Get Started Now 👋</h2>
      <p>Enter your credentials to access your account</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Full name</label>
          <div className="name-inputs">
            <input type="text" placeholder="First name" 
              style={{ fontSize: '24px' }}
            />
            <input type="text" placeholder="Last name" 
              style={{ fontSize: '24px' }}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Email address</label>
          <input type="email" placeholder="example@email.com" value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required
            style={{ fontSize: '24px' }} />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input type="password" placeholder="Min 8 chars" value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
              style={{ fontSize: '24px' }}
            />
        </div>
        <div className="checkbox">
          <label>
            <input type="checkbox" id="terms" />
            I agree to the <a href="#">Terms & Privacy</a>
          </label>
        </div>
        <button type="submit" className="register-button">Register</button>
        {/* <button type="button" className="wallet-button">Connect Wallet</button> */}
      </form>
      <WalletAuthButton mode="register" />
      <p className="sign-in-link">Already a member? <a href="/login">Sign In</a></p>
    </div>
  );
}

export default RegistrationForm;