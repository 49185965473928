// import { ConnectButton } from '@rainbow-me/rainbowkit';
// import { useAccount } from 'wagmi';
// import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { walletLogin, walletSignup } from '../../api';

// const WalletAuthButton = ({ mode = 'login' }) => {
//   const { address, isConnected } = useAccount();
//   const navigate = useNavigate();
//   const [isAuthenticating, setIsAuthenticating] = useState(false);

//   useEffect(() => {
//     const handleWalletAuth = async () => {
//       if (isConnected && address && !isAuthenticating) {
//         try {
//           setIsAuthenticating(true);
          
//           const authFunction = mode === 'login' ? walletLogin : walletSignup;
//           const response = await authFunction(address);
          
//           if (response.token) {
//             localStorage.setItem('token', response.token);
//             localStorage.setItem('wallet_address', address);
//             navigate('/home');
//           }
//         } catch (error) {
//           console.error('Wallet authentication failed:', error);
//           if (error.response?.status === 404 && mode === 'login') {
//             alert('Wallet not registered. Please register first.');
//             navigate('/signup');
//           }
//         } finally {
//           setIsAuthenticating(false);
//         }
//       }
//     };

//     handleWalletAuth();
//   }, [isConnected, address, navigate, mode]);

//   return (
//     <div className="wallet-auth-container w-full">
//       <ConnectButton 
//         label={mode === 'login' ? "Sign in with Wallet" : "Register with Wallet"}
//         accountStatus={{
//           smallScreen: 'avatar',
//           largeScreen: 'full',
//         }}
//         chainStatus={{
//           smallScreen: 'icon',
//           largeScreen: 'full',
//         }}
//         showBalance={false}
//       />
//       {isAuthenticating && (
//         <div className="text-sm text-gray-400 mt-2 text-center">
//           Authenticating...
//         </div>
//       )}
//     </div>
//   );
// };

// export default WalletAuthButton;

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { walletLogin, walletSignup } from '../../api';

const WalletAuthButton = ({ mode = 'login' }) => {
  const { address, isConnected } = useAccount();
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  useEffect(() => {
    const handleWalletAuth = async () => {
      if (isConnected && address && !isAuthenticating) {
        try {
          setIsAuthenticating(true);
          
          const authFunction = mode === 'login' ? walletLogin : walletSignup;
          const response = await authFunction(address);
          
          if (response.token) {
            localStorage.setItem('token', response.token);
            localStorage.setItem('wallet_address', address);
            navigate('/home');
          }
        } catch (error) {
          console.error('Wallet authentication failed:', error);
          if (error.response?.status === 404 && mode === 'login') {
            alert('Wallet not registered. Please register first.');
            navigate('/signup');
          }
        } finally {
          setIsAuthenticating(false);
        }
      }
    };

    handleWalletAuth();
  }, [isConnected, address, navigate, mode]);

  return (
    <div className="w-full">
      <ConnectButton.Custom>
        {({ openConnectModal }) => (
          <button 
            onClick={openConnectModal}
            className="w-full bg-gradient-to-r from-[#7e3bce] to-[#9f6ad8] text-white px-3 py-3 rounded-lg text-lg hover:opacity-90 transition-opacity"
          >
            {mode === 'login' ? "Sign in with Wallet" : "Register with Wallet"}
          </button>
        )}
      </ConnectButton.Custom>
      {isAuthenticating && (
        <div className="text-sm text-gray-400 mt-2 text-center">
          Authenticating...
        </div>
      )}
    </div>
  );
};

export default WalletAuthButton;