import peacepalImage from '../../assets/sign_up_login_image.png';
import './LeftPanel.css';

// TODO: Refactor left panel component to be broader in components file hierarchy 

const LeftPanel = () => {
  return (
    <div className="left-panel">
      <img src={peacepalImage} alt="PeacePal characters" className="peacepal-image" />
      <h1>PeacePal: Peace of mind at your fingertips</h1>
    </div>
  );
}

export default LeftPanel;