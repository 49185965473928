import React, { useState } from 'react';
import { Button } from "@nextui-org/react";
import { authenticatedRequest } from '../api';

const CheckoutButton = ({planType, text}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckout = async (planType) => {
    try {
      if (planType != "yearly" && planType != "monthly") {
          return
      }
      setIsLoading(true);
      
      const response = await authenticatedRequest.post('https://peacepal-gogettr-c2775e5dd645.herokuapp.com/create-checkout-session', { planType });
      
      // Now we expect a JSON response with a URL
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('No checkout URL received');
      }
      
    } catch (error) {
      console.error('Error:', error);
      alert('Something went wrong! Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      color="primary"
      onClick={() => {handleCheckout(planType)}}
      isLoading={isLoading}
      size="lg"
      radius="sm"
      className="bg-gradient-to-r from-blue-600 to-purple-600 text-white font-semibold"
    >
      {text}
    </Button>
  );
};

export default CheckoutButton;