import { Button } from "@nextui-org/react";

const BaseCheckoutButton = ({ planType, text }) => {
  const handleCheckout = () => {
    // Add your Coinbase Commerce links here
    const links = {
      monthly: "https://commerce.coinbase.com/checkout/6ca9ed2c-30f3-4f95-b6ca-e04d03f81359",
      yearly: "https://commerce.coinbase.com/checkout/b06b9ee4-a5ac-4836-b4c9-e0a561d77e4f"
    };

    window.location.href = links[planType];
  };

  return (
    <Button
      color="primary"
      onClick={handleCheckout}
      size="lg"
      radius="sm"
      className="bg-gradient-to-r from-blue-600 to-purple-600 text-white font-semibold"
    >
      {text}
    </Button>
  );
};

export default BaseCheckoutButton;