// // pages/success.js
// export default function Success() {
//     return (
//       <div className="text-center p-8">
//         <h1 className="text-2xl font-bold">Thanks for subscribing!</h1>
//         <p>Your subscription has been confirmed.</p>
//       </div>
//     );
//   }
  
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../public/peacepal_logo_2.png";

export default function Success() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/home');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen bg-[#191b29] flex items-center justify-center p-4">
      <Card className="w-full max-w-4xl bg-[#191b29] text-white">
        <CardHeader className="flex flex-col items-center gap-4 pb-2">
          <div className="flex justify-center">
            <img
              src={logo}
              alt="PeacePal Logo"
              className="h-48 md:h-64 w-auto"
            />
          </div>
          <div className="text-center space-y-2">
            <h1 className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-[#54a3c3] to-[#a16bdd] bg-clip-text text-transparent">
              You're All Set!
            </h1>
          </div>
        </CardHeader>

        <CardBody className="text-center text-gray-400">
          <p>Redirecting you to home page in 5 seconds...</p>
          <p className="mt-4">
            Click{" "}
            <span 
              onClick={() => navigate('/home')}
              className="text-[#54a3c3] cursor-pointer hover:underline"
            >
              here
            </span>{" "}
            if you haven't been redirected
          </p>
        </CardBody>
      </Card>
    </div>
  );
}